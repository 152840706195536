import './style.css'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'
import { gsap } from 'gsap'
import { random } from 'gsap/gsap-core'

/** 
 * To-Do
 * Lampe überarbeiten?
 * alles in html inside canvas, damit auch im Fullscreen sichtbar
**/

/** 
 * Colors
 * ("rgb(247, 239, 229)") creme #F7EFE5
 * ("rgb( 63, 171, 175)") blau #3FABAF
 * ("rgb(253,  88,  37)") orange #FD5825
 * ("rgb( 34,  34,  34)") schwarz #222222
**/

/**
 * Canvas 
**/

const canvas = document.querySelector('canvas.webgl');

/**
 * Scene
**/

const scene = new THREE.Scene();

/**
 * Overlay
**/

const overlayGeometry = new THREE.PlaneGeometry(2, 2, 1, 1);
const overlayMaterial = new THREE.ShaderMaterial({
    transparent: true,
    uniforms:{
        uAlpha: { value: 1}
    },
    vertexShader: `
        void main()
        {
            gl_Position = vec4(position, 1.0);
        }
    `,
    fragmentShader: `
        uniform float uAlpha;
        void main()
        {
            gl_FragColor = vec4(0.0, 0.0, 0.0, uAlpha);
        }
    `
});
const overlay = new THREE.Mesh(overlayGeometry, overlayMaterial);
scene.add(overlay);

/**
 * Sizes
**/

const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

/**
 * Camera
**/

const aspectRatio = sizes.width / sizes.height
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 1000)
//Orthographic Camera
//const zoomFaktor = 3;
//const camera = new THREE.OrthographicCamera(-zoomFaktor * aspectRatio,zoomFaktor * aspectRatio,zoomFaktor,-zoomFaktor, 0.1, 1000)
camera.position.set(-5,5,5)
scene.add(camera)

/**
 * Controls
**/

const controls = new OrbitControls(camera, canvas);
controls.enableDamping = true
controls.maxPolarAngle = Math.PI/180*80;      // 60° von oben nach unten
controls.screenSpacePanning = false;
controls.dampingFactor = 0.1;
controls.enablePan = false;
controls.target = new THREE.Vector3(0,0.8,0);
controls.autoRotateSpeed = 0.2;

//Orthographic Camera
/* controls.maxZoom = 1;   //wie nah
controls.minZoom = 0.2; // wie weit, kleiner = weiter */

//Perspektive Camera
controls.minDistance = 6;
controls.maxDistance = 7;

/**
 * Renderer
**/

const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    antialias: true
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
renderer.shadowMap.enabled = true

/**
 * Window Listener
**/

window.addEventListener('resize', () => 
{
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    camera.aspect = sizes.width / sizes.height
    // aspectRatio = sizes.width / sizes.height
    // camera.left = -zoomFaktor * aspectRatio
    // camera.right= zoomFaktor * aspectRatio
    // camera.top  = zoomFaktor
    // camera.bottom = -zoomFaktor
    camera.updateProjectionMatrix()

    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

window.addEventListener('dblclick', () => 
{
    if(!document.fullscreenElement)
    {
        canvas.requestFullscreen()
    }
    else
    {
        document.exitFullscreen()
    } 
})



/*################################Scene-Content################################*/

/**
 * Variablen
**/

var loadReady=0;

// 3D Points for HTML 
const points = [
    {
        position: new THREE.Vector3(0, 0.9, 0.8),
        element: document.querySelector('.point-0')
    }
]

/**
 *  Lights
 */

// Ambient Light 
const ambientLight = new THREE.AmbientLight(0xffffff, 0.8)
scene.add(ambientLight)

// Point Light
const pointLight = new THREE.PointLight(0xffffff, 0.3)
pointLight.position.set(0,3,0);
scene.add(pointLight);
pointLight.castShadow = true;

/**
 * Objects
 */

// Groundfloor
const floorGeo  = new THREE.BoxGeometry(400, 0.01, 400);
const floorMat  = new THREE.MeshStandardMaterial();
floorMat.color  = new THREE.Color("rgb(247, 239, 229)"); // creme
//floorMat.wireframe = true;
const floorMesh = new THREE.Mesh(floorGeo, floorMat);
scene.add(floorMesh);
floorMesh.receiveShadow = true;

// HDRI Sphere
const sphereGeo = new THREE.SphereGeometry( 200, 60, 40 );
sphereGeo.scale( - 1, 1, 1 );
const sphereMesh = new THREE.Mesh(sphereGeo, floorMat);
scene.add(sphereMesh);

// Test Cube
const testGeo1m = new THREE.BoxGeometry(1, 1, 1);
const testMat1m = new THREE.MeshBasicMaterial();
testMat1m.color = new THREE.Color("rgb(255, 0, 0)");
const testMesh1m = new THREE.Mesh(testGeo1m, testMat1m);
testMesh1m.position.set(-3.0, 0.5, 0) ;
//scene.add(testMesh1m);

/**
 * Loading 
**/

// Loading Manager
const loadingBarElement = document.querySelector('.loading-bar')
const loadingManager = new THREE.LoadingManager(
    // Loaded
    () =>
    {
        window.setTimeout(() =>
        {
            gsap.to(overlayMaterial.uniforms.uAlpha, { duration: 3, value: 0, delay: 1 })
            loadingBarElement.classList.add('ended')
            loadingBarElement.style.transform = ''
            document.querySelector('.Titel').style.opacity= 0;            
        }, 500)
        window.setTimeout(() =>
        {
            document.querySelector('.Titel').style.display="none";
        }, 1500)
        controls.minDistance = 2.2;
        controls.maxDistance = 10; 
        window.setTimeout(() =>
        {
        points[0].element.classList.add('visible');
        loadReady=1;
        document.querySelector('.Menu').style.display='inline-block';
        document.querySelector('.Menu').offsetHeight;
        document.querySelector('.Menu').style.opacity= '1';
        //controls.autoRotate = true;
        document.getElementsByClassName("open-button")[0].style.display = 'block';
        
        }, 4000)
    },
    // Progress
    (itemUrl, itemsLoaded, itemsTotal) =>
    {
        const progressRatio = itemsLoaded / itemsTotal
        loadingBarElement.style.transform = `scaleX(${progressRatio})`
    }
);

// GLTF Loader
var gltfLoader = new GLTFLoader(loadingManager)
var DracoLoader = new DRACOLoader()
DracoLoader.setDecoderPath( '/draco/' );
gltfLoader.setDRACOLoader( DracoLoader );

//const material4 = new THREE.MeshNormalMaterial()
const material4 = new THREE.MeshPhongMaterial()
//material4.wireframe = true
//material4.side = THREE.DoubleSide
var loadID = 0
var pivot = new THREE.Group();
scene.add( pivot );

//Moebel2
gltfLoader.load(
    '/models/test.glb',
    (gltf) =>
    {
        console.log('success')
        console.log(gltf)
        loadID = gltf.scene.id;
        //console.log( gltf.scene.id)
        //gltf.scene.material = material4
        //gltf.scene.scale.set(0.1,0.1,0.1)
        //gltf.scene.scale.set(6,6,6)
        
        // zentrieren
        var box = new THREE.Box3().setFromObject( gltf.scene );
        var center = box.getCenter( new THREE.Vector3() );
        
        gltf.scene.position.x += ( gltf.scene.position.x - center.x );
        //gltf.scene.position.y += ( gltf.scene.position.y - center.y );
        gltf.scene.position.z += ( gltf.scene.position.z - center.z );
        
        //gltf.scene.position.multiplyScalar( - 1 );
        pivot.add( gltf.scene );

        //scene.add(gltf.scene)
        // scene.getObjectById(loadID).castShadow = true 
        // scene.getObjectById(loadID).position.y = 1 
        
    }
)

/*
gltfLoader.load(
    '/models/monkey.gltf',
    (gltf) =>
    {
        console.log('success')
        gltf.scene.children[2].material = material4
        loadID = gltf.scene.children[2].id;
        scene.add(gltf.scene.children[2])
        scene.getObjectById(loadID).castShadow = true 
        scene.getObjectById(loadID).position.y = 1 
        
    }
)

*/

/**
 * Animated Mesh
 */

// const geometry = new THREE.BufferGeometry();

// const indices = [];

// const vertices = [];
// const normals = [];
// const colors = [];

// const size = 8;
// const segments = 4;

// const halfSize = size / 2;
// const segmentSize = size / segments;

// // generate vertices, normals and color data for a simple grid geometry

// for ( let i = 0; i <= segments; i ++ ) {

//     const z = ( i * segmentSize ) - halfSize;

//     for ( let j = 0; j <= segments; j ++ ) {

//         const x = ( j * segmentSize ) - halfSize;

//         vertices.push( x, 0, -z );
//         normals.push( 0, 1, 0 );

//         const r = ( x / size ) + 0.5;
//         const g = ( z / size ) + 0.5;

//         colors.push( r, g, 1 );

//     }

// }

// // generate indices (data for element array buffer)

// for ( let i = 0; i < segments; i ++ ) {

//     for ( let j = 0; j < segments; j ++ ) {

//         const a = i * ( segments + 1 ) + ( j + 1 );
//         const b = i * ( segments + 1 ) + j;
//         const c = ( i + 1 ) * ( segments + 1 ) + j;
//         const d = ( i + 1 ) * ( segments + 1 ) + ( j + 1 );

//         // generate two faces (triangles) per iteration

//         indices.push( a, b, d ); // face one
//         indices.push( b, c, d ); // face two

//     }

// }

// geometry.setIndex( indices );
// geometry.setAttribute( 'position', new THREE.Float32BufferAttribute( vertices, 3 ) );
// geometry.setAttribute( 'normal', new THREE.Float32BufferAttribute( normals, 3 ) );
// geometry.setAttribute( 'color', new THREE.Float32BufferAttribute( colors, 3 ) );

// const materialTest = new THREE.MeshBasicMaterial( {
//     side: THREE.DoubleSide,
//     vertexColors: true,
//     wireframe: true,
//     depthWrite: false,
//     depthTest: false,
//     transparent: true,
//     opacity: 0.6
// } );

// var mesh = new THREE.Mesh( geometry, materialTest );
// //mesh.scale.set(0.2,0.2,0.2)
// //mesh.rotation.x = Math.PI*0.5
// mesh.position.set(0,2.5,0)
// scene.add( mesh );
// //mesh.rotation.x=Math.PI;

// var count = 0
// const meshBewegung = () => {
//     if(count==0){
//         for( let i = 1; i < (segments+1)*(segments+1)+1; i ++ ){
//         mesh.geometry.attributes.position.array[i*3-2]= (Math.random()-0.5)
//         }
//         mesh.geometry.attributes.position.needsUpdate = true;
//     }
//     count++;
//     if (count==60*3){
//         count=0;
//     }
// }

/**
 * Animated Mesh 2
**/


let c = heatMap;
let ctx = c.getContext("2d");
var gradient = ctx.createLinearGradient(0, c.height, 0, 0);
gradient.addColorStop(0, "#3FABAF");
gradient.addColorStop(.5, "#F7EFE5");
gradient.addColorStop(1, "#FD5825");
ctx.fillStyle = gradient;
ctx.fillRect(0, 0, c.width, c.height);

let colorTexture = new THREE.CanvasTexture(c);
const size = 4;
let boxBorder = new THREE.Box3Helper(new THREE.Box3(new THREE.Vector3(- size, 0.01, - size), new THREE.Vector3(size, 4.01, size)), "gray");
scene.add(boxBorder);

let g = new THREE.PlaneBufferGeometry(size*2, size*2, 10, 10);
g.rotateX(-Math.PI * 0.5);

let uniforms = {
    colorTexture: {value: colorTexture},
    limits: {value: 0.4}
}

let m = new THREE.MeshBasicMaterial({
    side: THREE.DoubleSide,
    onBeforeCompile: shader => {
      shader.uniforms.colorTexture = uniforms.colorTexture;
      shader.uniforms.limits = uniforms.limits;
      shader.vertexShader = `
        varying vec3 vPos;
        ${shader.vertexShader}
      `.replace(
        `#include <fog_vertex>`,
        `#include <fog_vertex>
        vPos = vec3(position);
        `
      );
      shader.fragmentShader = `
        uniform float limits;
        uniform sampler2D colorTexture;
        
        varying vec3 vPos;
        ${shader.fragmentShader}
      `.replace(
        `vec4 diffuseColor = vec4( diffuse, opacity );`,
        `
          float h = (vPos.y - (-limits))/(limits * 2.);
          h = clamp(h, 0., 1.);
          vec4 diffuseColor = texture2D(colorTexture, vec2(0, h));
        `
      );
    }
})
//m.wireframe = true;
let o = new THREE.Mesh(g, m);
o.position.set(0,3,0);
scene.add(o);

function setRandHeight(time){
    let pos = g.attributes.position;
    let uv = g.attributes.uv;
    let mult = 2;
    for(let i = 0; i < pos.count; i++){
      let uvX = uv.getX(i) * mult;
      let uvY = uv.getY(i) * mult;
      pos.setY(i, noise.simplex3(uvX, uvY, time) * 1.5);
    }
    pos.needsUpdate = true;
}

o.visible = false;
boxBorder.visible= false;

var on = 0;
window.addEventListener('keypress', () => 
{
    if(on==0){
        document.querySelector('.heatMap').style.display='block';
        o.visible = true;
boxBorder.visible= true;
        on=1;
    } else{
        document.querySelector('.heatMap').style.display='none';
        o.visible = false;
        boxBorder.visible= false;
        on=0;l
    }
})

/**
 * Animate
 */

const clock = new THREE.Clock()
const tick = () =>
{   
    //meshBewegung();
    let t = clock.getElapsedTime();
    setRandHeight(t * 0.05);

    const elapsedTime = clock.getElapsedTime()
    // pivot.rotation.y = elapsedTime * Math.PI *2 *0.005
    
    //points[0].element.classList.add('visible');
    // Go through each point
    if(loadReady==1){
        //points[0].element.classList.add('visible');

        // $.ajax({
        //     url : 'index.php', // your php file
        //     type : 'GET', // type of the HTTP request
        //     success : function(data){
        //        var obj = jQuery.parseJSON(data);
        //        //console.log(obj);
        //        document.getElementsByClassName("point")[0].getElementsByClassName("text")[0].textContent = "Temp: " + obj[0] + " °C"
        //     }
        //  });

        var obj = 21.0;
         document.getElementsByClassName("point")[0].getElementsByClassName("text")[0].textContent = "Temp: " + obj + " °C"
    }
    
    for(const point of points)
    {
        const screenPosition = point.position.clone()
        screenPosition.project(camera)

        const translateX = screenPosition.x * sizes.width * 0.5
        const translateY = - screenPosition.y * sizes.height * 0.5
        point.element.style.transform = `translateX(${translateX}px) translateY(${translateY}px)`
    }

    if(loadID!=0){
        // point.element.classList.add('visible')
    }
        //scene.getObjectById(loadID).rotation.y = elapsedTime * Math.PI *2 *0.01
        //scene.getObjectById(loadID).rotateOnAxis(center, elapsedTime * Math.PI *2 *0.01)
        //scene.getObjectById(loadID).rotation.y = elapsedTime * Math.PI *2 *0.01
    //console.log("JOOOOO")
        //console.log(scene.getObjectByProperty("uuid", "DD1C1258-1956-4217-81FB-088E99C52954"))
    // }
    //scene.children[5].rotation.y = elapsedTime * Math.PI *2 *0.01
    //mesh.rotation.y = elapsedTime * Math.PI *2 *0.01
    // Update controls
    controls.update();

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick();